<template>
  <div id="create" v-resize="checkSize">

    <!-- <main-nav></main-nav> -->
    <template v-if="videoLoaded && buildVideo != null">
      <player :build="true" :video="buildVideo" :user="userData"></player>
    </template>

  </div>
</template>

<script>
// @ is an alias to /src
const player = () => import("@/components/player.vue");
const mainNav = () => import("@/components/shared/nav.vue");

import { mapGetters } from 'vuex'
import { db } from '@/firebase';

export default {
  name: "create",
  computed:{
    ...mapGetters({
      buildVideo:'GET_BUILD_VIDEO',
      viewVideo:'GET_CURRENT_VIDEO',
      userData: 'GET_USER'
    }),
  },
  metaInfo() {
    return{
      title: `Youtorial: Build some steps for ${this.$route.params.videoId}`
    }
  },
  components: {
    player, mainNav
  },
  data(){
    return{
      videoLoaded: false,
      video:null,
      pageId: 'Create',
    }
  },
  methods:{
    //get video info
    fetchVideoDetails(){
      // process.env.NODE_ENV === "development" ? console.log( 'this.currentVideo', this.currentVideo,  ) : null;
      let vThis = this;
      if(this.$isEmpty(this.buildVideo)){
        let videoId = vThis.$route.params.videoId
        let kind = 'build';
        this.$store.dispatch('getVideo', {videoId, kind})
        .then((results) => {
          // vThis.video = results;
          vThis.videoLoaded = true;
        })
        .catch((err) => {
          vThis.errorMessage = err;
          console.log('err',err);
          // vThis.videoLoaded = true;
          vThis.$router.push({name:'myYoutorials'})
        })
      }
      else{
       vThis.videoLoaded = true;
      }
    },
    checkSize(){
      if(this.$vuetify.breakpoint.mdAndUp){
        this.$store.commit('TOGGLE_DRAWER', false)
      }
    },
  },
  mounted(){
    this.fetchVideoDetails();
    this.$store.dispatch('toggleDrawer', false)
  },
  beforeDestroy(){
    console.log('before destroy');
    let video = {};
    this.$store.dispatch('setBuildVideo', video);
  }
};
</script>

<style lang="scss">
#create{
  height: 100%;
  // padding-top: 64px;
  // overflow: hidden;
  // display: grid;
  // grid-template-rows:64px 1fr;
  // grid-template-columns: 1fr;
  // grid-template-areas:
  // "header"
  // "video";
}
</style>
